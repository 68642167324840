<template>
  <div class="personal-list">
    <div class="begin mt-1">
      <div class="header-table flex items-center p-4 bg-white text-lg rounded-xl shadow border cursor-pointer">
        <div class="action w-1/12">
          <checkbox-base :check="isCheck" :id="position" @click.stop="clickOnCheckbox" />
        </div>
        <div class="action w-1/2 mr-4 flex items-center">
          <div
            :style="backgroundDefine"
            class="pictures w-12 h-12 text-center bg-gray-500 pt-4 mr-2 rounded-full text-sm font-bold text-white"
          >
            {{ getFirstLetter(personalsLists?.name) }}
          </div>
          <div class="ml-2">
            <div class="name">{{ personalsLists?.name }}</div>
            <div class="matricule text-sm text-gray-500">{{ personalsLists?.matricule }}</div>
          </div>
        </div>
        <div class="action w-1/3 mr-4">{{ personalsLists?.phone }}</div>
        <div class="action w-2/5 mr-4">{{ personalsLists?.title }}</div>
        <div class="action w-1/3 mr-4">
          <div
            v-if="personalsLists.status.toLowerCase() === 'active'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#324971', backgroundColor: 'rgba(50, 73, 113, 0.1)' }"
          >
            {{ $t('active') }}
          </div>
          <div
            v-if="personalsLists.status.toLowerCase() === 'inservice'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#009900', backgroundColor: 'rgba(0, 153, 0, 0.1)' }"
          >
            {{ $t('inService') }}
          </div>
          <div
            v-if="personalsLists.status.toLowerCase() === 'outservice'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#FBA705', backgroundColor: 'rgba(251, 167, 5, 0.1)' }"
          >
            {{ $t('resting') }}
          </div>
          <div
            v-if="personalsLists.status.toLowerCase() === 'onvacation'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#94A4C4', backgroundColor: 'rgba(148, 164, 196, 0.1)' }"
          >
            {{ $t('onLeave') }}
          </div>
          <div
            v-if="personalsLists.status.toLowerCase() === 'suspended'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#FB0505', backgroundColor: 'rgba(251, 5, 5, 0.1)' }"
          >
            {{ $t('suspended') }}
          </div>
          <div
            v-if="personalsLists.status.toLowerCase() === 'out'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#FB0505', backgroundColor: 'rgba(251, 5, 5, 0.1)' }"
          >
            {{ $t('out') }}
          </div>
          <div
            v-if="personalsLists.status.toLowerCase() === 'reserved'"
            class="status-action rounded-full px-4 py-1 w-fit text-center"
            :style="{ color: '#324971', backgroundColor: 'rgba(50, 73, 113, 0.1)' }"
          >
            {{ $t('reservist') }}
          </div>
        </div>
        <div class="action relative w-1/12 cursor-pointer" @click.stop="openOption">
          <icon :data="icons.down" original width="15" height="15"/>
          <div v-if="showOption" class="option text-left bg-white shadow-2xl absolute right-0 w-64 z-10">
            <div
              v-if="personalsLists.status.toLowerCase() === 'suspended'"
              class="option-list px-6 py-3 border-b"
              @click="changeStatusToActive"
            >
              {{ $t('activate') }}
            </div>
            <div v-else class="options-lists">
              <div
                class="option-list px-6 py-3 border-b"
                @click="changeStatusToSuspend"
              >
                {{ $t('toSuspend') }}
              </div>
              <div
                v-if="personalsLists.status !== 'onVacation'"
                class="option-list px-6 py-3 border-b"
                @click="changeStatusToOnLeave">
                {{ $t('putOnLeave') }}
              </div>
              <div
                v-if="personalsLists.status !== 'reserved'"
                class="option-list px-6 py-3 border-b"
                @click="changeStatusToReserve"
              >
                {{ $t('toReserve') }}
              </div>
              <div
                v-if="personalsLists.status !== 'outservice'"
                class="option-list px-6 py-3 border-b"
                @click="changeStatusToOutService"
              >
                {{ $t('resting') }}
              </div>
              <div
                v-if="personalsLists.status !== 'out'"
                class="option-list px-6 py-3 border-b"
                @click="changeStatusToOut"
              >
                {{ $t('out') + "r" }}
              </div>
            </div>
            <!--            <div class="option-list px-6 py-3 border-b" @click="changeStatusToArchive">{{ $t('archive') }}</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxBase from '@/components/helper/add/checkbox-base'
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore'
import { firestore } from '@/main'
import down from '@icon/ic_open_down.svg'
import http from '@/plugins/https'
export default {
  name: 'personal-list',
  components: { CheckboxBase },
  props: {
    personalsLists: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    position: {
      type: Number,
      default: 0
    },
    isCheck: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: { down },
      showOption: false,
      personalSite: [],
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }

    this.getPersonalSite()
  },
  methods: {
    changeStatusToActive () { // CHANGE USER STATUS TO ACTIVE AND ENABLE USER ACCOUNT
      const params = new URLSearchParams()
      const uid = this.personalsLists.uid
      params.append('uid', uid)
      http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleActiveAgent', params)
        .then(async response => {
          console.log(response)
          const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
          await updateDoc(statusRef, {
            status: 'active',
            updatedAt: new Date().getTime(),
            activatedBy: this.$store.getters.userId
          }).then(response => {
            console.log('Status updated successfully ' + response)
            // eslint-disable-next-line vue/no-mutating-props
            this.personalsLists.status = 'active'
            // window.location.reload()
          })
          const statusRefs = doc(firestore, 'personal', this.personalsLists.uid)
          await updateDoc(statusRefs, {
            status: 'active',
            updatedAt: new Date().getTime(),
            activatedBy: this.$store.getters.userId
          })
        }).catch(error => {
          console.log(error)
        })
    },
    changeStatusToOut () { // CHANGE USER STATUS TO OUT AND DISABLE USER ACCOUNT
      const params = new URLSearchParams()
      const uid = this.personalsLists.uid
      params.append('uid', uid)
      http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleSuspendAgent', params)
        .then(async response => {
          console.log(response)
          const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
          await updateDoc(statusRef, {
            status: 'out',
            updatedAt: new Date().getTime(),
            outedBy: this.$store.getters.userId
          }).then(response => {
            console.log('Status updated successfully ' + response)
            // eslint-disable-next-line vue/no-mutating-props
            this.personalsLists.status = 'out'
            // window.location.reload()
          })
          const statusRefs = doc(firestore, 'personal', this.personalsLists.uid)
          await updateDoc(statusRefs, {
            status: 'out',
            updatedAt: new Date().getTime(),
            outedBy: this.$store.getters.userId
          })
        }).catch(error => {
          console.log(error)
        })
    },
    changeStatusToSuspend () { // CHANGE USER STATUS TO SUSPENDED AND DISABLE USER ACCOUNT
      const params = new URLSearchParams()
      const uid = this.personalsLists.uid
      params.append('uid', uid)
      http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleSuspendAgent', params)
        .then(async response => {
          console.log(response)
          const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
          await updateDoc(statusRef, {
            status: 'suspended',
            updatedAt: new Date().getTime(),
            suspendedBy: this.$store.getters.userId
          }).then(response => {
            console.log('Status updated successfully ' + response)
            // eslint-disable-next-line vue/no-mutating-props
            this.personalsLists.status = 'suspended'
            // window.location.reload()
          })
          const statusRefs = doc(firestore, 'personal', this.personalsLists.uid)
          await updateDoc(statusRefs, {
            status: 'suspended',
            updatedAt: new Date().getTime(),
            suspendedBy: this.$store.getters.userId
          })
        }).catch(error => {
          console.log(error)
        })
    },
    async changeStatusToOnLeave () {
      const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
      await updateDoc(statusRef, {
        status: 'onVacation',
        updatedAt: new Date().getTime(),
        onLeaveBy: this.$store.getters.userId
      }).then(response => {
        console.log('Status updated successfully ' + response)
        // eslint-disable-next-line vue/no-mutating-props
        this.personalsLists.status = 'onVacation'
        // window.location.reload()
      })
      const statusRefs = doc(firestore, 'personal', this.personalsLists.uid)
      await updateDoc(statusRefs, {
        status: 'onVacation',
        updatedAt: new Date().getTime(),
        onLeaveBy: this.$store.getters.userId
      })
    },
    async changeStatusToReserve () {
      const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
      await updateDoc(statusRef, {
        status: 'reserved',
        updatedAt: new Date().getTime(),
        reservedBy: this.$store.getters.userId
      }).then(response => {
        console.log('Status updated successfully ' + response)
        // eslint-disable-next-line vue/no-mutating-props
        this.personalsLists.status = 'reserved'
        // window.location.reload()
      })
      const statusRefs = doc(firestore, 'personal', this.personalsLists.uid)
      await updateDoc(statusRefs, {
        status: 'reserved',
        updatedAt: new Date().getTime(),
        reservedBy: this.$store.getters.userId
      })
    },
    async changeStatusToOutService () {
      const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
      await updateDoc(statusRef, {
        status: 'outservice',
        updatedAt: new Date().getTime(),
        reservedBy: this.$store.getters.userId
      }).then(response => {
        console.log('Status updated successfully ' + response)
        // eslint-disable-next-line vue/no-mutating-props
        this.personalsLists.status = 'outservice'
        // window.location.reload()
      })
    },
    async changeStatusToArchive () {
      const statusRef = doc(firestore, 'agents', this.personalsLists.uid)
      await updateDoc(statusRef, {
        status: 'archived',
        updatedAt: new Date().getTime(),
        archivedBy: this.$store.getters.userId
      }).then(response => {
        console.log('Status updated successfully ' + response)
        // window.location.reload()
      })
      const statusRefs = doc(firestore, 'personal', this.personalsLists.uid)
      await updateDoc(statusRefs, {
        status: 'archived',
        updatedAt: new Date().getTime(),
        archivedBy: this.$store.getters.userId
      })
    },
    openOption () {
      this.showOption = !this.showOption
    },
    async getPersonalSite () {
      for (const i in this.personalsLists.site) {
        const q = query(collection(firestore, 'sites'), where('uid', '==', this.personalsLists.site[i]))

        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, ' => ', doc.data())
          this.personalSite.push(doc.data())
        })
      }
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    clickOnCheckbox () {
      if (this.isCheck) {
        this.$emit('isJustChecked', this.position)
      } else {
        this.$emit('isJustNotChecked', this.position)
      }
      console.log(this.position)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .header-table:hover {
    border-left: 2.5px solid $team_blue;
  }
  .svg-fill::v-deep {
    fill: transparent;
    path {
      stroke: #DDDDDD !important;
    }
  }
</style>
