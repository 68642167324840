<template>
  <div class="personal">
    <div class="begin pb-12">
      <!-- INDEX PAGE -->
      <div v-if="!isDetails && !isAddAgent" class="principal">
        <div class="header">
          <!-- HEADER POPUP -->
          <the-header
            :file-button="$t('importListsButton')"
            :add-button="$t('addPersonalButton')"
            :numbers="sortedArrayPersonal.length"
            @clickGreenButton="clickGreenButton"
            @clickBlueButton="clickBlueButton"
            @searchForItems="searchForItems"
          />
          <!-- HEADER POPUP END -->
        </div>

        <div class="body">
          <div class="header-table flex items-center p-4 bg-white rounded-xl border font-medium text-lg">
            <div class="action w-1/12">
              <checkbox-base :check="allCheck" @click="allCheckChange"/>
            </div>
            <div class="action w-1/2 mr-4">{{ $t('completeName') }}</div>
            <div class="action w-1/3 mr-4">{{ $t('contact') }}</div>
            <div class="action w-2/5 mr-4">{{ $t('job') }}</div>
            <div class="action w-1/3 mr-4 relative">
              <div class="flex items-center">
                <div class="mr-2">{{ $t('status') }}</div>
                <div class="cursor-pointer" @click="statusFilter = !statusFilter">
                  <icon :data="icons.dropdown" original width="25" height="25" />
                </div>
                <div class="text-base">{{ chosenStatus }}</div>
              </div>
              <div v-if="statusFilter" class="filter-by absolute bg-white shadow-2xl right-0 w-64 z-10 cursor-pointer">
                <div
                  v-for="(item, index) in allStatus"
                  :key="index"
                  class="option-list px-6 py-3 border-b"
                  @click="selectStatusFilter(index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="action w-1/12"></div>
          </div>
          <div class="header-body">
            <div class="loading" :class="{ 'not_empty': loading === false }">
              <loading
                :active="loading"
                :can-cancel="false"
                :on-cancel="onCancel"
                :is-full-page="fullPage"
                background-color="#FFFFFF"
                color="#324971"
                loader="dots"
              ></loading>
            </div>
            <div
              v-if="nothing"
              class="no-student text-center text-xl text-gray-500 py-32"
            >
              {{ $t('noPersonalAdded') }}
            </div>
            <div
              v-for="(item, index) in sortedArrayPersonal"
              :key="index"
              class="listing"
              @click="openPersonalDetail(index)"
            >
              <personal-list
                :personals-lists="item"
                :is-check="isAllChecked"
                @isJustChecked="isJustChecked"
                @isJustNotChecked="isJustNotChecked"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- PERSONAL DETAIL MODAL -->
      <personal-details
        v-if="isDetails"
        :one-details="oneDetails"
        @closePersonalDetail="closePersonalDetail"
        @editAgentProfile="editAgentProfile"
      ></personal-details>
    </div>

    <!-- CREATE PERSONAL MODAL -->
    <create-personal
      v-if="createPersonal"
      @closeCreatePersonal="closeCreatePersonal"
      @openSuccessCreate="openSuccessCreate"
    ></create-personal>

    <success-popup-base
      v-if="isSuccess"
      :success-description="$t('successPersonalAdded')"
      @closeSuccess="closeSuccess"
    ></success-popup-base>

    <!-- IMPORT PERSONAL MODAL -->
    <import-personal
      v-if="isImportList"
      @closeImport="closeImport"
      @openSuccessImport="openSuccessImport"
    ></import-personal>

    <!-- ADD NEW AGENT PAGE -->
    <add-agents
      v-if="isAddAgent"
      :agents-details-back="agentsDetails"
      @goBackIndex="goBackIndex"
    />
  </div>
</template>

<script>
import TheHeader from '@/components/the-header'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import CheckboxBase from '@/components/helper/add/checkbox-base'
import CreatePersonal from '@/components/helper/personal/create-personal'
import SuccessPopupBase from '@/components/helper/add/success-popup-base'
import { collection, query, onSnapshot, orderBy, where } from 'firebase/firestore'
import { firestore } from '@/main'
import PersonalList from '@/components/helper/personal/personal-list'
import ImportPersonal from '@/components/helper/personal/import-personal'
import PersonalDetails from '@/components/helper/personal/personal-details'
import dropdown from '../../assets/icons/ic_gridicons_dropdown.svg'
import AddAgents from '@/components/helper/agents/add-agents'
export default {
  name: 'index',
  components: { AddAgents, PersonalDetails, ImportPersonal, PersonalList, SuccessPopupBase, CreatePersonal, CheckboxBase, TheHeader, Loading },
  data () {
    return {
      icons: { dropdown },
      isImportList: false,
      allPersonal: [],
      isAllChecked: true,
      selectedInMatch: [],
      isNotSelected: [],
      allCheck: true,
      loading: true,
      fullPage: false,
      nothing: false,
      searching: '',
      createPersonal: false,
      isSuccess: false,
      isDetails: false,
      oneDetails: null,
      administrative: [],
      isAddAgent: false,
      agentsDetails: {},
      statusFilter: false,
      status: '',
      allStatus: [this.$t('reservist'), this.$t('active'), this.$t('inService'), this.$t('resting'), this.$t('suspended'), this.$t('onLeave'), this.$t('out'), this.$t('all')]
    }
  },
  created () {
    this.getAllPersonal()
  },
  computed: {
    sortedArrayPersonal: function () {
      let sorted = this.allPersonal

      let search = ''
      if (this.status !== '') {
        search = this.status
      } else {
        sorted = this.allPersonal
      }
      if (this.searching !== '') {
        search = this.searching
      }
      if (search !== '') {
        let authorNameSearchString = search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.name !== null && (item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.title !== null && (item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) ||
            (item.status.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    editAgentProfile (val) { // OPEN ADD AGENT TO EDIT SOME INFORMATION BASED ON AGENT PROFILE
      this.agentsDetails = val
      this.isAddAgent = true
    },
    goBackIndex (val) { // GO BACK TO INDEX
      this.isAddAgent = val
    },
    async selectStatusFilter (index) { // FILTER LIST BY STATUS
      this.statusFilter = false
      this.chosenStatus = this.allStatus[index]
      if (index === 0) {
        this.status = 'reserved'
      } else if (index === 1) {
        this.status = 'active'
      } else if (index === 2) {
        this.status = 'inService'
      } else if (index === 3) {
        this.status = 'outService'
      } else if (index === 4) {
        this.status = 'suspended'
      } else if (index === 5) {
        this.status = 'onVacation'
      } else if (index === 6) {
        this.status = 'out'
      } else if (index === 7) {
        this.status = ''
      }
      // console.log(this.status)
    },
    openPersonalDetail (index) {
      this.oneDetails = this.sortedArrayPersonal[index]
      this.isDetails = true
    },
    /* CLOSE PERSONAL DETAILS MODAL */
    closePersonalDetail (value) {
      this.isDetails = value
    },
    openSuccessImport (value) {
      this.isImportList = false
      this.isSuccess = value
    },
    getAllPersonal () {
      const q = query(collection(firestore, 'personal'),
        orderBy('uid'),
        where('uid', 'not-in', ['heXRjDk1RzcaTjr6MHMQS9iGGhy2', 'BHB2eiQOukOhc9KRxSzBaVvxiEI3']),
        orderBy('createdAt', 'desc')
      )
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          cities.push(doc.data())
          this.allPersonal = cities
          // console.log(this.allPersonal)
          this.allPersonal.sort((x, y) => {
            if (x.name < y.name) { return -1 }
            if (x.name > y.name) { return 1 }
            return 0
          })
        })
        if (this.allPersonal.length === 0) {
          this.loading = false
          this.nothing = true
        } else {
          this.loading = false
          this.nothing = false
        }
      })
    },
    openSuccessCreate (val) { // OPEN SUCCESS CREATE MODAL
      this.isSuccess = val
      this.createPersonal = false
    },
    closeSuccess (val) { // CLOSE SUCCESS CREATE MODAL
      this.isSuccess = val
    },
    closeCreatePersonal (val) { // CLOSE CREATE PERSONAL MODAL
      this.createPersonal = val
    },
    searchForItems (val) { // GET INPUT SEARCH
      this.searching = val
    },
    clickBlueButton (val) { // CLICK ON BLUE BUTTON TO OPEN ADD NEW AGENT PAGE
      this.createPersonal = val
    },
    closeImport (val) { // CLOSE IMPORT AGENT LIST POPUP
      this.isImportList = val
    },
    clickGreenButton (val) { // CLICK ON GREEN BUTTON TO OPEN IMPORT AGENT LIST POPUP
      this.isImportList = val
    },
    allCheckChange () {
      this.isAllChecked = this.allCheck
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
    isJustChecked (answer) {
      this.allCheck = false
      this.selectedInMatch.push(answer)
    },
    isJustNotChecked (answer) {
      for (let i = 0; i < this.selectedInMatch.length; i++) {
        if (this.selectedInMatch[i] === answer) {
          this.selectedInMatch.splice(i, 1)
        }
      }
      this.allCheck = false
      this.isNotSelected.push(answer)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
  .option-list:hover {
    background: rgb(243 244 246);
  }
</style>
