<template>
  <div class="personal-details">
    <div class="begin">
      <div class="header flex items-center justify-between mb-12">
        <div class="title-bloc flex items-center">
          <div class="icons mr-4 cursor-pointer" @click="goBack">
            <icon :data="icons.back" original width="40" height="40"/>
          </div>
          <div class="title font-semibold text-2xl">{{ $t('personalDetails') }}</div>
        </div>
      </div>

      <div class="body">
        <div class="body-content bg-white p-8 rounded-xl border flex">
          <div class="part-1 w-1/3 mr-3">
            <div class="edition p-6 w-full flex">
              <div class="w-1/5">
                <div
                  v-if="personalInfos?.profilePicture !== ''"
                  class="photo w-16 h-16 rounded-full bg-cover bg-no-repeat"
                  v-bind:style="{ backgroundImage: 'url(' + personalInfos?.profilePicture + ')' }"
                ></div>
                <div
                  v-else
                  :style="backgroundDefine"
                  class="pictures w-16 h-16 text-center bg-gray-500 pt-5 mr-2 rounded-full font-bold text-white"
                >
                  {{ getFirstLetter(personalInfos?.name) }}
                </div>
              </div>
              <div class="name-bloc ml-2 w-4/5">
                <div class="name font-medium text-xl">{{ personalInfos?.name }}</div>
                <div class="job">{{ oneDetails.title }}</div>
                <div class="edit flex items-center cursor-pointer" @click="editAgentProfile">
                  <div class="edit-title mr-4">{{ $t('editProfile') }}</div>
                  <div class="icons"><icon :data="icons.edition" original width="15" height="15"/></div>
                </div>
              </div>
            </div>
            <div class="identification p-6 w-full mt-4">
              <identification-infos v-if="personalInfos !== null" :is-identification="personalInfos" />
            </div>
            <div class="identification p-6 w-full mt-4">
              <contractual-infos v-if="personalInfos !== null" :is-contractual="personalInfos" />
            </div>
            <div class="identification p-6 w-full mt-4">
              <join-pieces v-if="personalInfos !== null" :is-join-pieces="personalInfos" />
            </div>
          </div>
          <div class="part-2 w-2/3 ml-3">
            <div v-if="!isAffectedEquipment && !isRequest" class="principal">
              <div class="stats w-full mb-4">
                <agent-stats
                  :agents-stats="personalInfos"
                  :request-number="allRequests.length"
                  @showRequests="showRequests"
                  @showAffectedEquipment="showAffectedEquipment"
                />
              </div>
            </div>
            <div class="second">
              <affected-equipment
                v-if="isAffectedEquipment"
                :details-agents="personalInfos"
              />
              <requests
                v-if="isRequest"
                :agent-requests="allRequests"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '@icon/ic_go_back.svg'
import picture from '@icon/ic_add_picture.svg'
import edition from '@icon/ic_pencil.svg'
import { doc, getDoc } from 'firebase/firestore'
import { firestore } from '@/main'
import IdentificationInfos from '@/components/helper/agents/identification-infos'
import ContractualInfos from '@/components/helper/agents/contractual-infos'
import JoinPieces from '@/components/helper/agents/join-pieces'
import AgentStats from '@/components/helper/agents/agent-stats'
import AffectedEquipment from '@/components/helper/agents/affected-equipment'
import Requests from '@/components/helper/agents/requests'
export default {
  name: 'personal-details',
  components: { Requests, AffectedEquipment, AgentStats, JoinPieces, ContractualInfos, IdentificationInfos },
  props: {
    oneDetails: Object
  },
  data () {
    return {
      icons: { back, picture, edition },
      personalInfos: null,
      allRequests: [],
      isAffectedEquipment: false,
      isRequest: false,
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    this.getPersonalInformation()
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    editAgentProfile () { // CLICK TO OPEN EDIT AGENT PROFILE
      this.$emit('editAgentProfile', this.personalInfos)
      this.goBack()
      // console.log(this.personalInfos)
    },
    async getPersonalInformation () {
      const docRef = doc(firestore, 'agents', this.oneDetails.uid)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data())
        this.personalInfos = docSnap.data()
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
    },
    showRequests (val) {
      this.isRequest = val
    },
    showAffectedEquipment (val) {
      this.isAffectedEquipment = val
    },
    getFirstLetter (fullName) {
      const nameTable = fullName.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    goBack () {
      this.$emit('closePersonalDetail', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .edition, .identification {
    background: $team_gray_popup;
  }
  .photo {
    background-image: url("../../../assets/images/img_user.webp");
  }
  .edit-title {
    color: #017D01;
  }
</style>
