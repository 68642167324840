<template>
  <div class="create-personal">
    <popup-base>
      <div class="begin m-auto w-3/5 bg-white border">
        <div class="content">
          <div class="header p-8 flex justify-between">
            <div class="title-bloc">
              <div class="title text-2xl font-medium">{{ $t('addPersonalButton') }}</div>
            </div>
            <div class="close cursor-pointer" @click="closeAdd">
              <icon :data="icons.close" original width="25" height="25"/>
            </div>
          </div>
          <div class="body p-8">
            <div class="form">
              <div class="form-content">
                <div class="label mb-2">{{ $t('completeName') }}*</div>
                <div class="input-bloc">
                  <input v-model="name" type="text" class="ipt w-full p-4 border rounded-lg" />
                </div>
              </div>
              <br />
              <div class="form-content">
                <div class="label mb-2">{{ $t('email') }}</div>
                <div class="input-bloc">
                  <input v-model="email" type="email" class="ipt w-full p-4 border rounded-lg" />
                </div>
              </div>
              <br />
              <div class="form-content">
                <div class="label mb-2">{{ $t('phone') }}*</div>
                <div class="input-bloc">
                  <input v-model="phone" type="number" class="ipt w-full p-4 border rounded-lg" />
                </div>
              </div>
              <br />
              <div class="form-content">
                <div class="label mb-2">{{ $t('address') }}</div>
                <div class="input-bloc">
                  <input v-model="address" type="text" class="ipt w-full p-4 border rounded-lg" />
                </div>
              </div>
              <br />
              <div class="form-content">
                <div class="label mb-2">{{ $t('job') }}*</div>
                <div class="input-bloc">
                  <input v-model="title" type="text" class="ipt w-full p-4 border rounded-lg" />
                </div>
              </div>
              <br />
              <div class="form-content">
                <div class="label mb-2">{{ $t('site') }}</div>
                <div class="input-bloc">
                  <Multiselect
                    v-model="value"
                    :options="allSite"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    trackBy="client"
                    label="name"
                    valueProp="uid"
                    :classes="{
                      container:
                        'relative mx-auto w-full p-4 flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded-lg bg-white text-base leading-snug outline-none',
                    }"
                  />
<!--                  <input v-model="site" type="text" class="ipt w-full p-4 border rounded-lg" @input="onChange" />
                  <div v-if="isSite" class="sites-lists absolute w-full">
                    <div class="listing border bg-white h-24 overflow-y-scroll w-full">
                      <div
                        v-for="(item, index) in results"
                        :key="index"
                        class="px-4 py-2 cursor-pointer hover:bg-gray-300"
                        @click="selectedSite(index)"
                      >
                        {{ item.identification.client + ' (' + item.identification.name + ')' }}
                      </div>
                    </div>
                  </div>-->
                </div>
              </div>
              <div v-if="error" class="error text-center text-red-500 mt-6">
                {{ $t('errorPersonal') }}
              </div>
              <br />
              <div class="login-button flex items-center justify-end">
                <div class="cancel-button mr-3">
                  <button-base
                    :label="$t('goBackButton')"
                    fontcolor="#909090"
                    background="#FFFFFF"
                    @click="closeAdd"
                  />
                </div>
                <div class="valid-button ml-3">
                  <button-base
                    :label="$t('saveButton')"
                    :is-loading="loader"
                    @click="saveNewPersonal()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '@/components/helper/add/popup-base'
import close from '../../../assets/icons/ic_close.svg'
import ButtonBase from '@/components/helper/add/button-base'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { firestore } from '@/main'
import Multiselect from '@vueform/multiselect'
import http from '@/plugins/https'
export default {
  name: 'create-personal',
  components: { ButtonBase, PopupBase, Multiselect },
  data () {
    return {
      icons: { close },
      name: '',
      phone: null,
      email: '',
      title: '',
      site: '',
      value: null,
      address: '',
      loader: false,
      allSite: [],
      results: [],
      isSite: false,
      selected: '',
      error: false
    }
  },
  created () {
    this.getAllSite()
  },
  methods: {
    saveNewPersonal () {
      if (this.name === '' || this.phone === '' || this.title === '') {
        this.error = true
      } else {
        this.error = false
        this.loader = true

        let matricule = ''
        matricule = 'AC' + '-' + Math.random().toString(8).substr(2, 7) + this.name.charAt(0).toUpperCase()
        console.log(matricule)

        const params = new URLSearchParams()
        const agentEmail = matricule + '@teamsecu.com'
        params.append('email', agentEmail)
        http.post('https://us-central1-teamsecu-f002b.cloudfunctions.net/exampleCreate', params)
          .then(async response => {
            console.log(response)
            console.log(response.uid)

            // CREATE AGENT INFORMATION
            const updateAgent = doc(firestore, 'agents', response.uid)
            await setDoc(updateAgent, {
              uid: response.uid,
              createdAt: new Date().getTime(),
              name: this.name,
              matricule: matricule,
              profilePicture: '',
              email: this.email,
              birthday: null,
              phone: this.phone,
              city: '',
              address: this.address,
              agentFunction: 'administrative',
              hiringDate: null,
              contractType: '',
              contractTerm: null,
              salary: null,
              addedBy: this.$store.getters.userId,
              birthdayAct: '',
              identityCard: '',
              identityPicture: '',
              residenceAttestation: '',
              childrenBirthdayAct: '',
              wifeBirthdayAct: '',
              weddingAct: '',
              recommendationLetter: '',
              criminalRecord: '',
              takeServiceId: '',
              status: 'active',
              isDesignated: false,
              haveEquipment: false,
              numberAffected: 0,
              serviceSite: this.value
            }).then(res => {
              console.log('Created successful:' + res)
            }).catch(err => {
              console.log(err)
            })

            // CREATE PERSONAL TABLE
            const data = {
              uid: response.uid,
              createdAt: new Date().getTime(),
              addedBy: this.$store.getters.userId,
              name: this.name,
              phone: this.phone,
              email: this.email,
              title: this.title,
              site: this.value,
              address: this.address,
              status: 'active',
              matricule: matricule
            }

            await setDoc(doc(firestore, 'personal', response.uid), data)

            await this.$emit('openSuccessCreate', true)
          }).catch(error => {
            console.log(error)
          })
      }
    },
    filterResults () {
      this.results = this.allSite.filter(
        (item) =>
          item.identification.client
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(
              this.site
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            ) > -1
      )
    },
    onChange () {
      this.filterResults()
      this.isSite = true
    },
    selectedSite (index) {
      if (this.site === '') {
        this.isSite = false
      } else {
        console.log(this.results[index].uid)
        this.site = this.results[index].identification.client + ' (' + this.results[index].identification.name + ')'
        this.selected = this.results[index].uid
        this.isSite = false
      }
    },
    async getAllSite () {
      let data = {}
      const querySnapshot = await getDocs(collection(firestore, 'sites'))
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        data = {
          client: doc.data().identification.client,
          name: doc.data().identification.name,
          uid: doc.data().uid
        }
        this.allSite.push(data)
      })
      console.log(this.allSite)
    },
    closeAdd () {
      this.$emit('closeCreatePersonal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header {
    background: $team_gray_popup;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
